<template>
  <div class="scrolllayout">
    <div class="scrolllayout-flex" style="flex:1;">
      <div class="about" style="display: flex;flex-direction: column;flex: 1;height: 100%">
        <nav1></nav1>
        <div style="background-color: white;width: 917px;margin: 20px auto;flex: 1;">
<!--          <div style="font-size: 25px;font-weight: bold;text-align: center;padding: 10px">{{ detail.name }}</div>-->
<!--          <div style="color: #999999;font-size: 16px;margin-top: 10px">{{ detail.intro }}</div>-->
          <div  class="detail" v-html="detail.content"></div>
        </div>
      </div>
    </div>
    <footer1></footer1>
  </div>
</template>

<script>
//导航栏组件
import nav1 from "../components/nav/nav1";
//页脚组件
import footer1 from "../components/footer/footer1";
import {getDetail} from "@/api/about";

export default {
  name: "Detail"
  , components: {
    nav1
    , footer1
  }
  , data() {
    return {

      detail: ''

    }
  },
  created() {


    let id = this.$route.query.id;

    this._getDetail(id)


  },
  methods: {

    _getDetail(id) {

      getDetail(id).then(res => {

        this.detail = res;

      });

    }


  },
  watch:{
    "$route"(to) {
      //请求数据的方法

      let id = to.query.id


      this._getDetail(id);


    }
  }
}
</script>

<style scoped>


</style>